import * as React from 'react';
import PropTypes from 'prop-types';
import {Global, ThemeProvider} from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import DateRangeWrapper from "../../../partials/bookings/DateRangeWrapper";
import {useDispatch, useSelector} from "react-redux";
import dynamic from "next/dynamic";
import Guests from "../../../../index/bookingSearchBar/partials/Guests";
import {useEffect, useState} from "react";
import Popover from "../../../../../../helpers/class/Popover";
import Dialog from "../../../../../../helpers/class/Dialog";
import cloneDeep from 'lodash/cloneDeep'
import {
    fetchPropertiesAction,
    setAdults, setApplyParams, setBedrooms, setCheckin, setCheckout,
    setInfants,
    setKids, setLoadingProperties,
    setOffset,
    setPageNumber, setSearchSpinner, setSearchText
} from "../../../../../../redux/actions";
import Checkers from "../../../../../../helpers/class/Checkers";
import {getWindow} from "ssr-window";
import AsyncCreatableSelect from "react-select/async-creatable";
import {loadSearchOptionsAction} from "../../../../../../api/client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt as farMapMarkerAlt} from "@fortawesome/pro-regular-svg-icons";
import {getDefaultOptions} from "../../../../index/bookingSearchBar/FullWidthBar";
import {customAsyncSelectStyles} from "../../../../../../helpers/StylesHelper";
import axios from "axios";
import {fetchBuilderSettingByKey} from "../../../../../../helpers/MethodHelper";
import {useRouter} from "next/router";

const drawerBleeding = 0;

const Root = styled('div')(({ theme }) => ({
    height: '100%',
    backgroundColor:
        theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

const MobileStickyBar = dynamic(() => import("./MobileStickyBar"), {
    ssr: false,
})

function guestsTextCalc ( adults_amount, kids_amount, infants_amount ) {
    const guests_sum =  adults_amount + kids_amount;
    if ( guests_sum > 1 && infants_amount > 1 )
        return guests_sum + ' guests, ' + infants_amount + ' infants'
    else if ( guests_sum > 1 && infants_amount == 1 )
        return guests_sum + ' guests, 1 infant'
    else if ( guests_sum == 1 && infants_amount > 1 )
        return '1 guest, ' + infants_amount + ' infants'
    else if ( guests_sum == 1 && infants_amount == 1 )
        return '1 guest, 1 infant'
    else if ( guests_sum > 1 )
        return guests_sum + ' guests'
    else if ( guests_sum == 1 )
        return '1 guest'
    else
        return 'Who'
}

function SwipeableDrawerFilters(props : { calendar, window?, forHomepage? }) {
    const popoverMaterialTheme = Popover.getPopoverMaterialTheme;
    const dialogMaterialTheme = Dialog.getDialogMaterialTheme;

    const [popover_anchorEl, popover_setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const popover_handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        popover_setAnchorEl(event.currentTarget);
        document.getElementById('guests_label').classList.add('fade');
        document.getElementById('guests_label').parentElement.classList.add('focused');
    };

    const popover_handleClose = () => {
        document.getElementById('guests_label').classList.remove('fade');
        document.getElementById('guests_label').parentElement.classList.remove('focused');
        popover_setAnchorEl(null);
    };
    const popover_open = Boolean(popover_anchorEl);
    const popover_id = popover_open ? 'simple-popover' : undefined;

    const [adults_amount, adults_setAmount] = useState(0);
    const [kids_amount, kids_setAmount] = useState(0);
    const [infants_amount, infants_setAmount] = useState(0);

    const [adults_decreaseHidden, adults_setDecreaseHidden] = useState(true);
    const [kids_decreaseHidden, kids_setDecreaseHidden] = useState(true);
    const [infants_decreaseHidden, infants_setDecreaseHidden] = useState(true);
    const [adults_increaseHidden, adults_setIncreaseHidden] = useState(false);
    const [kids_increaseHidden, kids_setIncreaseHidden] = useState(false);
    const [infants_increaseHidden, infants_setIncreaseHidden] = useState(false);

    const [guests_text, guests_setText] = useState('Who');

    const adults_handleIncrease = () => {
        adults_setAmount(adults_amount => adults_amount + 1);

        if ((adults_amount + 1) > 0)
            adults_setDecreaseHidden(false);
        else
            adults_setDecreaseHidden(true);

        if ((adults_amount + 1) < 30)
            adults_setIncreaseHidden(false);
        else
            adults_setIncreaseHidden(true);

        guests_setText(() => guestsTextCalc((adults_amount + 1), kids_amount, infants_amount))
    };
    const adults_handleDecrease = () => {
        adults_setAmount(adults_amount => adults_amount - 1);

        if ((adults_amount - 1) < 1)
            adults_setDecreaseHidden(true);
        else if ((kids_amount > 0 || infants_amount > 0) && (adults_amount - 1) < 2)
            adults_setDecreaseHidden(true);
        else
            adults_setDecreaseHidden(false);

        if ((adults_amount - 1) >= 0)
            adults_setIncreaseHidden(false);
        else
            adults_setIncreaseHidden(true);

        guests_setText(() => guestsTextCalc((adults_amount - 1), kids_amount, infants_amount))
    };
    const kids_handleIncrease = () => {
        kids_setAmount(kids_amount => kids_amount + 1);

        if ((kids_amount + 1) > 0)
            kids_setDecreaseHidden(false);
        else
            kids_setDecreaseHidden(true);

        if ((kids_amount + 1) < 30)
            kids_setIncreaseHidden(false);
        else
            kids_setIncreaseHidden(true);

        if (adults_amount == 0) {
            adults_setAmount(adults_amount => adults_amount + 1);
            adults_setDecreaseHidden(true);
            guests_setText(() => guestsTextCalc((adults_amount + 1), (kids_amount + 1), infants_amount))
        } else {
            guests_setText(() => guestsTextCalc(adults_amount, (kids_amount + 1), infants_amount))
            if (adults_amount < 2)
                adults_setDecreaseHidden(true);
            else
                adults_setDecreaseHidden(false);
        }
    };
    const kids_handleDecrease = () => {
        kids_setAmount(kids_amount => kids_amount - 1);

        if ((kids_amount - 1) < 1)
            kids_setDecreaseHidden(true);
        else
            kids_setDecreaseHidden(false);

        if ((kids_amount - 1) >= 0)
            kids_setIncreaseHidden(false);
        else
            kids_setIncreaseHidden(true);

        if (adults_amount == 1)
            if ((kids_amount - 1) < 1 && infants_amount < 1)
                adults_setDecreaseHidden(false);
            else
                adults_setDecreaseHidden(true);
        else
            adults_setDecreaseHidden(false);

        guests_setText(() => guestsTextCalc(adults_amount, (kids_amount - 1), infants_amount))
    };
    const infants_handleIncrease = () => {
        infants_setAmount(infants_amount => infants_amount + 1);

        if ((infants_amount + 1) > 0)
            infants_setDecreaseHidden(false);
        else
            infants_setDecreaseHidden(true);

        if ((infants_amount + 1) < 30)
            infants_setIncreaseHidden(false);
        else
            infants_setIncreaseHidden(true);

        if (adults_amount == 0) {
            adults_setAmount(prevState => prevState + 1);
            adults_setDecreaseHidden(true);
            guests_setText(() => guestsTextCalc((adults_amount + 1), kids_amount, (infants_amount + 1)))
        } else {
            guests_setText(() => guestsTextCalc(adults_amount, kids_amount, (infants_amount + 1)))

            if (adults_amount < 2)
                adults_setDecreaseHidden(true);
            else
                adults_setDecreaseHidden(false);
        }
    };
    const infants_handleDecrease = () => {
        infants_setAmount(infants_amount => (infants_amount - 1));

        if ((infants_amount - 1) < 1)
            infants_setDecreaseHidden(true);
        else
            infants_setDecreaseHidden(false);

        if ((infants_amount - 1) >= 0)
            infants_setIncreaseHidden(false);
        else
            infants_setIncreaseHidden(true);

        if (adults_amount == 1)
            if (kids_amount < 1 && (infants_amount - 1) < 1)
                adults_setDecreaseHidden(false);
            else
                adults_setDecreaseHidden(true);
        else
            adults_setDecreaseHidden(false);

        guests_setText(() => guestsTextCalc(adults_amount, kids_amount, (infants_amount - 1)))
    };


    //-------------DIALOG-------------
    const [dialog_open, dialog_setOpen] = React.useState(false);
    // const [selectedValue, setSelectedValue] = React.useState(emails[1]);

    const dialog_handleOpen = () => {
        dialog_setOpen(true);
    };

    const dialog_handleClose = (value: string) => {
        dialog_setOpen(false);
    };

    const setGuestsAmountZero = () => {

        dispatch(setAdults(0))
        dispatch(setKids(0))
        dispatch(setInfants(0))
        dispatch(setBedrooms(0))
        dispatch(setCheckin(null))
        dispatch(setCheckout(null))

        adults_setAmount(0);
        kids_setAmount(0);
        infants_setAmount(0);

        adults_setDecreaseHidden(true);
        kids_setDecreaseHidden(true);
        infants_setDecreaseHidden(true);

        guests_setText(() => guestsTextCalc(0, 0, 0));

        dialog_handleClose('close');
    }

    const popover = {
        //-----POP_OVER Variables, Handlers-------
        adults: {
            handleIncrease: adults_handleIncrease,
            handleDecrease: adults_handleDecrease,
            decreaseHidden: adults_decreaseHidden,
            increaseHidden: adults_increaseHidden,
            setDecreaseHidden: adults_setDecreaseHidden,
            setIncreaseHidden: adults_setIncreaseHidden,
            setAmount: adults_setAmount,
            amount: adults_amount
        },
        kids: {
            handleIncrease: kids_handleIncrease,
            handleDecrease: kids_handleDecrease,
            decreaseHidden: kids_decreaseHidden,
            increaseHidden: kids_increaseHidden,
            setDecreaseHidden: kids_setDecreaseHidden,
            setIncreaseHidden: kids_setIncreaseHidden,
            setAmount: kids_setAmount,
            amount: kids_amount,
        },
        infants: {
            handleIncrease: infants_handleIncrease,
            handleDecrease: infants_handleDecrease,
            decreaseHidden: infants_decreaseHidden,
            increaseHidden: infants_increaseHidden,
            setDecreaseHidden: infants_setDecreaseHidden,
            setIncreaseHidden: infants_setIncreaseHidden,
            setAmount: infants_setAmount,
            amount: infants_amount,

        },
        setGuestsAmountZero: setGuestsAmountZero,
        guestsText: guests_text,
        guestsSetText: guests_setText,
        guestsTextCalc: guestsTextCalc,
        customTheme: popoverMaterialTheme,
        handleOpen: popover_handleOpen,
        handleClose: popover_handleClose,
        anchorEl: popover_anchorEl,
        open: popover_open,
        id: popover_id
    };

    const dialog = {
        handleClose: dialog_handleClose,
        handleOpen: dialog_handleOpen,
        setOpen: dialog_setOpen,
        open: dialog_open,
        customTheme: dialogMaterialTheme
    };

    const isMobile = useSelector(state => state['appSettings']['isMobile'])

    const {window} = props;
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const toggleDrawerV2 = (newOpen) => {
        setOpen(newOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const dispatch = useDispatch()

    const propertiesParams = useSelector(state => state['propertiesParams']);

    const uiSearchBarDivHeight = useSelector(state => state['uiData']['searchBarDivHeight'])

    const showSearchInput = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_general_show_search_in_mobile_panel')
    );


    const isListingPage = props.hasOwnProperty('forHomepage') ? !props.forHomepage : false

    const searchProperties = () => {
        dispatch( setSearchSpinner(true) )
        if (Checkers.valueToBoolean(showSearchInput)){
            searchHandler()
            return;
        }

        if (props.forHomepage != undefined && props.forHomepage) {

            let queryString = Checkers.serialize(propertiesParams);
            router.push( "/properties/search/?" + queryString);
            event.preventDefault()
        } else {
            searchHandler()
            // dispatch(setOffset(0))
            // dispatch(setPageNumber(1))
            // dispatch<any>(fetchPropertiesAction(propertiesParams, 0, uiSearchBarDivHeight))
        }
    }

    const router = useRouter()

    const searchHandler = ( event = null ) => {
        dispatch( setOffset( 0 ) )
        dispatch( setPageNumber( 1 ) )
        propertiesParams.offset = 0

        if ( selectedLocation==null ) {
            let queryString = Checkers.serialize( propertiesParams );
            router.push( "/properties/search/?" + queryString );
            if (event!=null) {
                event.preventDefault()
            }
            return;
        }

        let location_val = selectedLocation.value;
        let location_data = location_val.split( "_" );
        let location_type = location_data[0];
        let location_id = location_data[1];

        if ( isListingPage && location_id==propertiesParams.destinations[0]) {
            dispatch( setLoadingProperties( true ) );
            dispatch<any>( fetchPropertiesAction( propertiesParams ) )
            dispatch( setApplyParams( 1 ) )
        } else {
            let queryString = Checkers.serialize( propertiesParams );

            switch ( location_type ) {
                case 'destination':
                    router.push( "/"  + selectedLocation.slug + "?" + queryString );
                    break;
                case 'location':
                    router.push( "/"  + selectedLocation.slug + "?" + queryString );
                    break;
                default:
                    propertiesParams.search = location_val
                    queryString = Checkers.serialize( propertiesParams );
                    router.push( "/properties/search/?" + queryString );
                    break;
            }
        }
        if (event!=null) {
            event.preventDefault()
        }
    };


    const [ selectedLocation, setSelectedLocation ]= useState( null );

    const pageId = useSelector(state => state['propertiesParams']['page_id']);

    const destinations = useSelector( state => state['propertiesParams']['destinations']);

    const customSearch = useSelector( state => state['propertiesParams']['search']);

    const [ defaults, setDefaults ] = useState( null );

    const options = [
        {
            label: "Destinations",
            options: [
                { label: "Rethymno", value: "Rethymno" },
                { label: "Heraklion", value: "Heraklion" }
            ]
        },
        {
            label: "Properties",
            options: [
                { label: "Rethymno Villa 1", value: "Rethymno Villa 1" },
                { label: "Rethymno Villa 2", value: "Rethymno Villa 2" }
            ]
        },
    ];

    const loadOptions = ( inputValue, callback ) => {
        axios.post( `/apiloggia`, {
            action: `/api/lodge/frontend/get/search-suggestions/all?page_id=` + pageId + "&search=" + inputValue,
            useCache: false
        }).then( response => {

            const options = [];
            let data = response.data;

            data = data.filter( ( i ) =>
                i.title.toLowerCase().includes( inputValue.toLowerCase() )
            );

            data.forEach( ( suggestion ) => {
                options.push({
                    label: suggestion.title,
                    value: suggestion.id,
                    slug: suggestion.slug
                })
            })
            callback( options );
        });
    }

    useEffect( () => {
        if (Checkers.valueToBoolean(showSearchInput)) {
            getDefaultOptions(pageId)
                .then(response => {

                    setDefaults(response.options);
                    let destination = Checkers.isValidArray(destinations) ? destinations[0] : null

                    Checkers.isValidArray(response.options) && response.options.map((item) => {
                        if (item.value == 'destination_' + destination) {
                            setSelectedLocation(item)
                        }
                    })

                    if (customSearch != null) {
                        let customOption = {
                            value: customSearch,
                            label: customSearch
                        }
                        setSelectedLocation(customOption)
                    }
                });
        }
    }, []);

    const handleInputChange = ( input ) => {
        let location_val = input.value;
        let location_data = location_val.split( "_" );
        let location_type = location_data[0];
        let location_id = location_data[1];

        if ( location_type != 'property') {
            setSelectedLocation(input);
            dispatch( setSearchText( input.label ))
        } else {
            let customOption = {
                value: input.label,
                label: input.label
            }
            setSelectedLocation(customOption)
            dispatch( setSearchText( input.label ))
        }
    };

    const searchSelectLabel = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_properties_select_label')
    );

    return (
        <>
            {
                isMobile ?
                    <>
                        <MobileStickyBar forList={true} toggleDrawer={toggleDrawerV2} drawerOpened={open}
                                         searchProperties={searchProperties} setGuestsAmountZero={setGuestsAmountZero}/>
                        <Root>
                            <CssBaseline/>
                            <Global
                                styles={{
                                    '.MuiDrawer-root > .MuiPaper-root': {
                                        height: `calc(90% - ${drawerBleeding}px)`,
                                        overflow: 'visible',
                                        borderTopLeftRadius: "8px",
                                        borderTopRightRadius: "8px"
                                    },
                                }}
                            />
                            <SwipeableDrawer
                                sx={{zIndex: "1200", position: 'relative'}}
                                container={container}
                                anchor="bottom"
                                open={open}
                                onClose={toggleDrawer(false)}
                                onOpen={toggleDrawer(true)}
                                swipeAreaWidth={drawerBleeding}
                                disableSwipeToOpen={false}
                                disableBackdropTransition={false}
                                ModalProps={{
                                    keepMounted: false,
                                }}
                            >
                                <StyledBox
                                    // style={{marginTop: "40px"}}
                                    sx={{
                                        px: 2,
                                        pb: 2,
                                        // height: '100%',
                                        overflow: 'auto',
                                        borderTopRightRadius: '4px',
                                        borderTopLeftRadius: '4px'
                                    }}
                                >
                                    <Puller onClick={toggleDrawer(!open)}/>
                                    <div style={{display: "flex", marginTop: "40px"}}>
                                        {/*, height: "100%"*/}
                                        <div className={'row align-center overflow-scroll'} style={{marginBottom: '80px'}}>
                                            {
                                                Checkers.valueToBoolean(showSearchInput) ?
                                                    <div className="col-12 col-lg-4 px-2 m-auto p-0" style={{maxWidth: "350px"}}>
                                                        <div className={'form-group form-group-default form-group-default-select2 bg-transparent no-margin no-arrow overflow-visible'}>
                                                            <label htmlFor="location_property" className={'form-label '}>
                                                                <FontAwesomeIcon icon={farMapMarkerAlt}/> {Checkers.isValidString(searchSelectLabel) ? searchSelectLabel : 'Location/Property'}
                                                            </label>
                                                            <AsyncCreatableSelect
                                                                cacheOptions={false}
                                                                defaultOptions={defaults}
                                                                loadOptions={loadOptions}
                                                                onChange={handleInputChange}
                                                                value={selectedLocation}
                                                                className="SearchBar-Form__form-group-select"
                                                                id="location_property"
                                                                name="location_property"
                                                                inputId="location_property"
                                                                instanceId="location_property"
                                                                isSearchable
                                                                options={options}
                                                                styles={customAsyncSelectStyles}
                                                                placeholder={'Where are you going'}
                                                                blurInputOnSelect
                                                                menuPlacement={'bottom'}
                                                                formatCreateLabel={ (inputValue: string) => {
                                                                    return ('Search for ' + inputValue)
                                                                }}
                                                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            <div className={'col-12 align-center d-flex justify-content-center p-b-0 p-t-10'}>
                                                <div className="row w-100 no-padding no-margin"
                                                     style={{maxWidth: '350px'}}>
                                                    <DateRangeWrapper forList={true} theme={props.calendar.customTheme}
                                                                      isStatic={1} toggleDrawer={toggleDrawerV2}
                                                                      containerClass={'p-0 border-bottom'}
                                                    />
                                                </div>
                                            </div>
                                            <div className={'col-12 align-center d-flex justify-content-center p-b-80'}>
                                                <Guests popover={popover} dialog={dialog} mobile={false} labelWeight={''}/>
                                            </div>
                                        </div>
                                    </div>
                                </StyledBox>
                            </SwipeableDrawer>
                        </Root>
                    </>
                    :
                    <></>
            }
        </>
    );
}

SwipeableDrawerFilters.propTypes = {
    window: PropTypes.func,
};

export default SwipeableDrawerFilters;