import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faMinus as fasMinus, faPlus as fasPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import Divider from '@mui/material/Divider';

import Checkers from '../../../../../helpers/class/Checkers';
import {
  decreaseAdults, decreaseBedrooms,
  decreaseInfants,
  decreaseKids,
  increaseAdults, increaseBedrooms, increaseInfants,
  increaseKids, updateDateRangeValid
} from '../../../../../redux/actions';
import {enableBookingBtn, fetchBuilderSettingByKey} from "../../../../../helpers/MethodHelper";

interface GuestsInteface {
  adultsNum?: number
  setAdultsNum?: (val: number) => void
  kidsNum?: number
  setKidsNum?: (val: number) => void
  infantsNum?: number
  setInfantsNum?: (val: number) => void
  dispatchValues?: boolean
  mobile: boolean
  popover: any
  dialog: any
  labelWeight?: string
  viewType?: string|boolean
}

export default function Guests ( props : GuestsInteface ) {

  const dispatchValues = props.hasOwnProperty('dispatchValues') ? props.dispatchValues : true

  const [localAdultsNum, setLocalAdultsNum] = useState(props.hasOwnProperty('adultsNum') ? props.adultsNum : 0)

  const [localKidsNum, setLocalKidsNum] = useState(props.hasOwnProperty('kidsNum') ? props.kidsNum : 0)

  const [localInfantsNum, setLocalInfantsNum] = useState(props.hasOwnProperty('infantsNum') ? props.infantsNum : 0)

  useEffect(() => {
    setLocalAdultsNum(props.hasOwnProperty('adultsNum') ? props.adultsNum : 0)
    setLocalKidsNum(props.hasOwnProperty('kidsNum') ? props.kidsNum : 0)
    setLocalInfantsNum(props.hasOwnProperty('infantsNum') ? props.infantsNum : 0)
  }, [props.adultsNum]);

  const dispatch = useDispatch();

  const labelWeight = props.hasOwnProperty('labelWeight') ? props.labelWeight : 'bold'

  const viewType = props.hasOwnProperty('viewType') ? props.viewType : false

  const isDesktop = useSelector(state => state['appSettings']['isDesktop']);

  const bookingSearchbarWithBedrooms = useSelector(state => fetchBuilderSettingByKey(state['builderSettingsData'], 'bookingSearchbarWithBedrooms'));

  const property_id = useSelector(state => state['propertiesParams']['property_id'])

  const propertiesParams = useSelector(state => state['propertiesParams']);

  const bookingFormTriggered = useSelector(state => state['appSettings']['bookingFormTriggered'])

  const priceLoading = useSelector(state => state['appSettings']['priceLoading'])

  const capacityMax = useSelector(state => state['appSettings']['capacityMax'])

  const propertyInitialAvailability = useSelector(state => state['propertyAvailability']['initialAvailability'])

  const adultsNum = useSelector(state => state['propertiesParams']['adults'])

  const kidsNum = useSelector(state => state['propertiesParams']['kids'])

  const infantsNum = useSelector(state => state['propertiesParams']['infants'])

  const personsForBooking = dispatchValues ? (adultsNum + kidsNum + infantsNum) : (localAdultsNum + localKidsNum + localInfantsNum)

  useEffect(() => {
    let popoverText = [];

    let adults = dispatchValues ? (parseInt(propertiesParams.adults) + parseInt(propertiesParams.kids)) : localAdultsNum + localKidsNum
    if (adults > 0) {
      popoverText.push(adults + " adults")
    }

    let infants = dispatchValues ? (parseInt(propertiesParams.infants)) : localInfantsNum
    if (infants > 0) {
      popoverText.push(infants + " infants")
    }

    if (popoverText.length > 0) {
      props.popover.guestsSetText(popoverText.join(", "));
    } else {
      props.popover.guestsSetText("Who will stay");
    }
  }, [propertiesParams, localAdultsNum, localKidsNum, localInfantsNum])

  const decreaseAdultsHandler = function () {
    if (dispatchValues) {
      dispatch(decreaseAdults())
    } else if (props.setAdultsNum!=undefined) {
      props.setAdultsNum(localAdultsNum-1)
      setLocalAdultsNum(localAdultsNum-1)
    }
  }

  const increaseAdultsHandler = function () {
    if (dispatchValues) {
      dispatch(increaseAdults())
    } else if (props.setAdultsNum!=undefined) {
      props.setAdultsNum(localAdultsNum+1)
      setLocalAdultsNum(localAdultsNum+1)
    }
  }

  const decreaseKidsHandler = function () {
    if (dispatchValues) {
      dispatch(decreaseKids())
    } else if (props.setKidsNum!=undefined) {
      props.setKidsNum(localKidsNum-1)
      setLocalKidsNum(localKidsNum-1)
    }
  }

  const increaseKidsHandler = function () {
    if (dispatchValues) {
      dispatch(increaseKids())
    } else if (props.setKidsNum!=undefined) {
      props.setKidsNum(localKidsNum+1)
      setLocalKidsNum(localKidsNum+1)
    }
  }

  const decreaseInfantsHandler = function () {
    if (dispatchValues) {
      dispatch(decreaseInfants())
    } else if (props.setInfantsNum!=undefined) {
      props.setInfantsNum(localInfantsNum-1)
      setLocalInfantsNum(localInfantsNum-1)
    }
  }

  const increaseInfantsHandler = function () {
    if (dispatchValues) {
      dispatch(increaseInfants())
    } else if (props.setAdultsNum!=undefined) {
      props.setInfantsNum(localInfantsNum+1)
      setLocalInfantsNum(localInfantsNum+1)
    }
  }

  const decreaseBedroomsHandler = function () {
    if (dispatchValues) {
      dispatch(decreaseBedrooms())
    }
  }

  const increaseBedroomsHandler = function () {
    if (dispatchValues) {
      dispatch(increaseBedrooms())
    }
  }

  const dateRangeValid = useSelector(state => state['propertiesParams']['dateRangeValid'])

  const enableButtons = enableBookingBtn(propertyInitialAvailability, priceLoading, personsForBooking, dateRangeValid)


  const hasError = () => {
    if (bookingFormTriggered && !enableButtons) {
      return true
    }

    return false
  }

  if (!Checkers.isValidObj(props, 'popover')) {
    return null;
  }
  return (

      <div className="row w-100 padding-20 no-margin" style={{maxWidth: '350px'}}>

        {
          !bookingSearchbarWithBedrooms || Checkers.isValidNumber(property_id) ?
              <>
                {
                  viewType == 'property' ?
                      <p className={'text-danger fs-12 p-0 ' + (bookingFormTriggered && hasError() ? 'visible' : 'invisible')}>Please
                        select number of guests</p>
                      :
                      <></>
                }
                <div className="col-12 d-flex justify-content-between pt-1 pb-3 px-2">
                  <div className="d-flex flex-column">
                    <label className={labelWeight + " fs-16"}>Adults</label>
                    <p className="hint-text semi-bold fs-12 no-margin">13 years old and over</p>
                    <label className={labelWeight + " fs-16 hidden"}>Ενήλικες</label>
                    <p className="hint-text semi-bold fs-12 no-margin hidden">Ηλικίας 13 ετών και άνω</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <button onClick={decreaseAdultsHandler} disabled={dispatchValues ? propertiesParams['adults'] == 0 : localAdultsNum == 0}
                            className="d-flex justify-content-center align-items-center btn-decrease bg-transparent text-master">
                      <FontAwesomeIcon className="fs-16 semi-bold" icon={fasMinus} style={{fontSize: '20px'}}/>
                    </button>
                    <input id="adults" type="number" className="guest-input border-0 text-center"
                           pattern="^-?[0-9]\d*\.?\d*$"
                           min={(props.popover.kids.amount > 0 || props.popover.infants.amount > 0) ? '1' : '0'}
                           max="30"
                           readOnly={props.mobile}
                           disabled={true}
                           value={dispatchValues ? propertiesParams.adults : localAdultsNum}
                           autoComplete={'off'}
                           style={{width: '30px', margin: '0 7px', fontSize: '16px'}}/>
                    <button onClick={increaseAdultsHandler}
                            disabled={Checkers.isValidPositiveNumber(capacityMax) && personsForBooking >= capacityMax}
                            className="d-flex justify-content-center align-items-center btn-increase bg-transparent text-master">
                      <FontAwesomeIcon className="fs-16 semi-bold" icon={fasPlus} style={{fontSize: '20px'}}/>
                    </button>
                  </div>
                </div>

                <Divider/>

                <div className="col-12 d-flex justify-content-between py-3 px-2">
                  <div className="d-flex flex-column">
                    <label className={labelWeight + " fs-16"}>Kids</label>
                    <p className="hint-text semi-bold fs-12 no-margin">Ages 2-12</p>
                    <label className={labelWeight + " fs-16 hidden"}>Παιδιά</label>
                    <p className="hint-text semi-bold fs-12 no-margin hidden">Ηλικίας 2-12</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <button onClick={decreaseKidsHandler} disabled={dispatchValues ? propertiesParams.kids == 0 : localKidsNum == 0}
                            className="d-flex justify-content-center align-items-center btn-decrease bg-transparent text-master">
                      <FontAwesomeIcon className="fs-16 semi-bold" icon={fasMinus} style={{fontSize: '20px'}}/>
                    </button>
                    <input id="kids" type="number" className="guest-input border-0 text-center"
                           pattern="^-?[0-9]\d*\.?\d*$"
                           min="0" max="30"
                           readOnly={props.mobile}
                           disabled={true}
                           value={dispatchValues ? propertiesParams.kids : localKidsNum}
                           autoComplete={'off'}
                           style={{width: '30px', margin: '0 7px', fontSize: '16px'}}/>
                    <button onClick={increaseKidsHandler}
                            disabled={Checkers.isValidPositiveNumber(property_id) && personsForBooking >= capacityMax}
                            className="d-flex justify-content-center align-items-center btn-increase bg-transparent text-master">
                      <FontAwesomeIcon className="fs-16 semi-bold" icon={fasPlus} style={{fontSize: '20px'}}/>
                    </button>
                  </div>
                </div>

                <Divider/>

                <div className="col-12 d-flex justify-content-between pt-3 pb-3 pb-lg-1 px-2">
                  <div className="d-flex flex-column">
                    <label className={labelWeight + " fs-16"}>Infants</label>
                    <p className="hint-text semi-bold fs-12 no-margin">under 2</p>
                    <label className={labelWeight + " fs-16 hidden"}>Βρέφη</label>
                    <p className="hint-text semi-bold fs-12 no-margin hidden">κάτω από 2</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <button onClick={decreaseInfantsHandler} disabled={dispatchValues ? propertiesParams.infants == 0 : localInfantsNum == 0}
                            className="d-flex justify-content-center align-items-center btn-decrease bg-transparent text-master">
                      <FontAwesomeIcon className="fs-16 semi-bold" icon={fasMinus} style={{fontSize: '20px'}}/>
                    </button>
                    <input id="infants" type="number" className="guest-input border-0 text-center"
                           pattern="^-?[0-9]\d*\.?\d*$" min="0" max="30"
                           readOnly={props.mobile}
                           disabled={true}
                           value={dispatchValues ? propertiesParams.infants : localInfantsNum}
                           autoComplete={'off'}
                           style={{width: '30px', margin: '0 7px', fontSize: '16px'}}/>
                    <button onClick={increaseInfantsHandler}
                            disabled={Checkers.isValidPositiveNumber(property_id) && personsForBooking >= capacityMax}
                            className="d-flex justify-content-center align-items-center btn-increase bg-transparent text-master">
                      <FontAwesomeIcon className="fs-16 semi-bold" icon={fasPlus} style={{fontSize: '20px'}}/>
                    </button>
                  </div>
                </div>

                {/*<Divider/>*/}
              </>
              :
              <>
                <div className="col-12 d-flex justify-content-between pt-3 pb-3 pb-lg-1 px-2">
                  <div className="d-flex flex-column">
                    <label className={labelWeight + " fs-16"}>Bedrooms</label>
                    {/*<p className="hint-text semi-bold fs-12 no-margin">under 2</p>*/}
                    <label className={labelWeight + " fs-16 hidden"}>Βρέφη</label>
                    <p className="hint-text semi-bold fs-12 no-margin hidden">κάτω από 2</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <button onClick={decreaseBedroomsHandler}
                            className="d-flex justify-content-center align-items-center btn-decrease bg-transparent text-master">
                      <FontAwesomeIcon className="fs-16 semi-bold" icon={fasMinus} style={{fontSize: '20px'}}/>
                    </button>
                    <input id="infants" type="number" className="guest-input border-0 text-center"
                           pattern="^-?[0-9]\d*\.?\d*$" min="0" max="30"
                           readOnly={props.mobile}
                           disabled={true}
                           value={propertiesParams.bedrooms}
                           autoComplete={'off'}
                           style={{width: '30px', margin: '0 7px', fontSize: '16px'}}/>
                    <button onClick={increaseBedroomsHandler}
                            className="d-flex justify-content-center align-items-center btn-increase bg-transparent text-master">
                      <FontAwesomeIcon className="fs-16 semi-bold" icon={fasPlus} style={{fontSize: '20px'}}/>
                    </button>
                  </div>
                </div>
              </>
        }


        {
          isDesktop ?
              <>
                <div className={"col-12 m-t-20"}>
                  <div className={'row d-flex p-0 text-right justify-content-end'}>
                    <Button className={"col-auto btn-design-color btn btn-primary text-lowercase"}
                            onClick={props.popover.handleClose}>
                      close
                    </Button>
                  </div>
                </div>
              </>
              :
              <></>
        }
      </div>
  )
}
