import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import dynamic from 'next/dynamic';
import
{
  faBed as farBed,
  faCalendar as farCalendar,
  faMapMarkerAlt as farMapMarkerAlt,
  faUserFriends as farUserFriends,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dialog from '@mui/material/Dialog';
import Popover from '@mui/material/Popover';
import { ThemeProvider } from '@mui/material/styles';
import axios from 'axios';

import Checkers from '../../../../helpers/class/Checkers';
import {
  addDestination,
  fetchPropertiesAction, removeAllDestination, setAdults,
  setApplyParams, setBedrooms, setDateRange, setDestination, setInfants, setKids,
  setLoadingProperties,
  setOffset, setPageNumber, setSearchSpinner, setSearchText
} from '../../../../redux/actions';
import * as appContext from '../../../AppContext';

import CalendarCheckin from './partials/CalendarCheckin';
import CalendarCheckout from './partials/CalendarCheckout';
import Guests from './partials/Guests';
import {OnChangeValue} from "react-select/dist/declarations/src/types";
import {fetchBuilderSettingByKey, getBedroomValue, updateBedrooms} from "../../../../helpers/MethodHelper";
import {useRouter} from "next/router";
import {faSpinner} from "@fortawesome/pro-solid-svg-icons";
import {DateRange} from "@mui/lab/DateRangePicker";
import {de} from "date-fns/locale";
import dayjs from "dayjs";

const Select = dynamic( () => import( 'react-select' ), { ssr: false } );

export async function getDefaultOptions ( pageId )  {
  return await axios.post( `/apiloggia`, {
    action: `/api/lodge/frontend/get/search-suggestions/default?page_id=` + pageId,
    useCache: false
  }).then( async (response) => {
    let defaultLocationValue;
    const options = [];
    let data = response.data;
    await data.forEach( async ( suggestion ) => {
      await options.push({
        label: suggestion.title,
        value: suggestion.id,
        slug: suggestion.slug
      })


      if ( suggestion.slug==window.location.pathname ) {
        defaultLocationValue = {
          value: suggestion.id,
          label: suggestion.title,
          slug: suggestion.slug
        }
      }
    })
    return { options, defaultLocationValue };
  });
}

export default function FullWidthBar ( props ) {

  const dispatch = useDispatch()

  const router = useRouter()

  const currentDestination = useSelector(state => state['appSettings']['currentDestination'])

  const propertiesParams = useSelector( state => state['propertiesParams']);

  const customSearch = useSelector( state => state['propertiesParams']['search']);

  const pageId = useSelector( state => state['propertiesParams']['page_id']);

  const destinations = useSelector( state => state['propertiesParams']['destinations']);

  const isListingPage = useSelector( state => state['propertiesParams']['isListingPage']);

  const isSearchPage = useSelector( state => state['propertiesParams']['isSearchPage']);

  const pageContext = useContext( appContext.page );

  const [ selectedLocation, setSelectedLocation ] = useState( null );

  const searchSpinner = useSelector( state => state['appSettings']['searchSpinner']);

  const searchSelectLabel = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_properties_select_label')
  );

  const [removeAllDestinations, setRemoveAllDestinations] = useState(false)

  const [localSearchText, setLocalSearchText] = useState(null)

  const [localDestinationId, setLocalDestinationId] = useState(null)

  const checkin = useSelector(state => state['propertiesParams']['checkin'])

  const checkout = useSelector(state => state['propertiesParams']['checkout'])

  const [localDateRange, setLocalDateRange] = useState<DateRange<Date>>([null, null]);

  const bedrooms = useSelector( state => state['propertiesParams']['bedrooms']);

  const [localBedrooms, setLocalBedrooms] = useState(bedrooms)

  const [localAdultsNum, setLocalAdultsNum] = useState(0)

  const [localKidsNum, setLocalKidsNum] = useState(0)

  const [localInfantsNum, setLocalInfantsNum] = useState(0)

  useEffect(() => {
    setLocalAdultsNum(propertiesParams.adults)
    setLocalKidsNum(propertiesParams.kids)
    setLocalInfantsNum(propertiesParams.infants)
  }, [propertiesParams]);

  useEffect(() => {
    let checkin_unix = new Date(checkin).valueOf() ? new Date(checkin) : null
    let checkout_unix = new Date(checkout).valueOf() ? new Date(checkout) : null

    setLocalDateRange([checkin_unix, checkout_unix])
  }, [checkin, checkout, propertiesParams])

  useEffect(() => {
    setLocalBedrooms(bedrooms)
  }, [bedrooms, propertiesParams])

  const dateRangeHandler = (val: DateRange<Date>) => {
    props.calendar.setValue(val)
    props.calendar.focusToggle('close_checkin');
    setLocalDateRange(val)
  }

  const handleInputChange = ( input ) => {
    let location_val = input.value;
    let location_data = location_val.split( "_" );
    let location_type = location_data[0];
    let location_id = location_data[1];

    if ( location_type == 'all' ) {
      setSelectedLocation(input);
      setRemoveAllDestinations(true)
      setLocalSearchText('')
    } else if ( location_type == 'destination') {
      setSelectedLocation(input);
      setRemoveAllDestinations(false)
      setLocalDestinationId(location_id)
      setLocalSearchText('')
    } else if ( location_type != 'property') {
      setSelectedLocation(input);
      setRemoveAllDestinations(true)
      setLocalSearchText(input.label)
    } else {
      let customOption = {
        value: input.label,
        label: input.label
      }

      setSelectedLocation(customOption)
      setRemoveAllDestinations(true)
      setLocalSearchText(input.label)
    }
  };

  const dispatchValues = async () => {
    if (removeAllDestinations) {
      dispatch( removeAllDestination())
    } else if (localDestinationId!=null) {
      dispatch( setDestination( localDestinationId ))
    }

    dispatch( setSearchText( localSearchText ))

    dispatch(setDateRange(localDateRange))

    dispatch( setBedrooms(localBedrooms) )

    dispatch( setAdults(localAdultsNum))

    dispatch( setKids(localKidsNum))

    dispatch( setInfants(localInfantsNum))

    return true
  }

  const searchHandler = async ( event ) => {
    await dispatchValues()

    dispatch( setOffset( 0 ) )
    dispatch( setPageNumber( 1 ) )

    propertiesParams.offset = 0
    propertiesParams.adults = localAdultsNum
    propertiesParams.kids = localKidsNum
    propertiesParams.infants = localInfantsNum
    propertiesParams.bedrooms = localBedrooms
    propertiesParams.search = localSearchText

    if (removeAllDestinations) {
      propertiesParams.destinations = []
    } else if (localDestinationId!=null) {
      propertiesParams.destinations = [localDestinationId]
    }

    if (localDateRange[0]!=null) {
      propertiesParams.checkin = dayjs(localDateRange[0]).format('YYYY-MM-DD')
    } else {
      propertiesParams.checkin = null
    }

    if (localDateRange[1]!=null) {
      propertiesParams.checkout = dayjs(localDateRange[1]).format('YYYY-MM-DD')
    } else {
      propertiesParams.checkout = null
    }

    if ( selectedLocation==null && !isSearchPage ) {
      let queryString = Checkers.serialize( propertiesParams );
      router.push( "/properties/search/?" + queryString );
      event.preventDefault()
      return;
    }

    let location_id = ''
    let location_val = ''
    if (selectedLocation!=null) {
      location_val = selectedLocation.value;
      let location_data = location_val.split("_");
      let location_type = location_data[0];
      location_id = location_data[1];
    }

    dispatch( setSearchSpinner(true) )

    if ( isListingPage && currentDestination==propertiesParams.destinations[0] && Checkers.isValidPositiveNumber(location_id)) {
      dispatch( setLoadingProperties( true ) );
      dispatch<any>( fetchPropertiesAction( propertiesParams ) )
      dispatch( setApplyParams( 1 ) )
    } else if ( isSearchPage ) {
      dispatch( setLoadingProperties( true ) );
      dispatch<any>( fetchPropertiesAction( propertiesParams ) )
      dispatch( setApplyParams( 1 ) )

      let queryString = Checkers.serialize( propertiesParams );
      propertiesParams.search = location_val
      const newUrl = "/properties/search/?" + queryString
      window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl)
    } else {
      let queryString = Checkers.serialize( propertiesParams );

      propertiesParams.search = location_val
      router.push( "/properties/search/?" + queryString );
    }
    event.preventDefault()
  };

  useEffect(() => {
    let popoverText = [];

    let adults = localAdultsNum + localKidsNum
    if (adults > 0) {
      popoverText.push(adults + " adults")
    }

    let infants = localInfantsNum
    if (infants > 0) {
      popoverText.push(infants + " infants")
    }

    if (popoverText.length > 0) {
      props.popover.guestsSetText(popoverText.join(", "));
    } else {
      props.popover.guestsSetText("Who will stay");
    }
  }, [localAdultsNum, localKidsNum, localInfantsNum])

  const loadOptions = ( inputValue, callback ) => {
    axios.post( `/apiloggia`, {
      action: `/api/lodge/frontend/get/search-suggestions/all?page_id=` + pageContext.pageId + "&search=" + inputValue,
      useCache: false
    }).then( response => {
      const options = [];
      let data = response.data;

      data = data.filter( ( i ) =>
          i.title.toLowerCase().includes( inputValue.toLowerCase() )
      );

      data.forEach( ( suggestion ) => {
        options.push({
          label: suggestion.title,
          value: suggestion.id,
          slug: suggestion.slug
        })
      })
      callback( options );
    });
  }

  const [ defaults, setDefaults ] = useState( null );
  useEffect( () => {
    getDefaultOptions( pageId )
        .then( response => {

          setDefaults( response.options );
          let destination = Checkers.isValidArray(destinations) ? destinations[0] : null

          let destinationFlag = false
          Checkers.isValidArray(response.options) && response.options.map( (item) => {
            if (item.value == 'destination_' + destination) {
              setSelectedLocation( item )
              destinationFlag = true
            }
          })

          if ( Checkers.isValidString(customSearch) && !destinationFlag ) {
            let customOption = {
              value: customSearch,
              label: customSearch
            }
            setSelectedLocation( customOption )
          } else if (!destinationFlag){
            let customOption = {
              value: 'all_properties',
              label: 'All properties'
            }
            setSelectedLocation( customOption )
          } else if ( destinationFlag ) {
            setLocalDestinationId(destination)
          }
        });
  }, [destinations, propertiesParams]);

  return (
      <div className={'test-1 bg-design-color searchbar-bg-color\ ' + props.fixed}>
        <div className="container">
          <form id="frm_book_2" onSubmit={event => searchHandler( event )} className="row justify-content-center full-width no-margin needs-validation padding-20">
            <div className={props.isManager == true ? 'col-12 col-lg-10 d-flex flex-wrap no-padding' : 'col-12 d-flex flex-wrap no-padding'}>
              {
                props.isManager == true ?
                    <div className="col-12 col-lg-4 pb-3 pb-lg-0 px-2">
                      <div className={'form-group form-group-default form-group-default-select2 bg-transparent no-margin no-arrow overflow-visible\ ' + props.borderColor + '\ ' + props.textColor}>
                        <label htmlFor="location_property" className={'form-label\ ' + props.textColor}>
                          <FontAwesomeIcon icon={farMapMarkerAlt}/> {Checkers.isValidString(searchSelectLabel) ? searchSelectLabel : 'Location/Property'}</label>
                        <AsyncCreatableSelect
                            // onFocus={ (event) => {
                            //   setSearchFieldIsFocused(true)
                            // }}
                            // onBlur={ (event) => {
                            //   setSearchFieldIsFocused(false)
                            // }}
                            cacheOptions={false}
                            defaultOptions={defaults}
                            loadOptions={loadOptions}
                            onChange={handleInputChange}
                            value={selectedLocation}
                            className="SearchBar-Form__form-group-select"
                            id="location_property"
                            name="location_property"
                            inputId="location_property"
                            instanceId="location_property"
                            formatCreateLabel={ (inputValue: string) => {
                              return ('Search for ' + inputValue)
                            }}
                            isSearchable
                            options={props.options}
                            styles={props.customStyles}
                            placeholder={'Where are you going'}
                            blurInputOnSelect
                            menuPlacement={'bottom'}
                            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                        />
                      </div>
                    </div>
                    :''
              }

              <div className={props.isManager == true ? 'col-6 col-lg-3 pb-3 pb-lg-0 px-2' : 'col-6 col-lg-4 pb-3 pb-lg-0 px-2'}>
                <div
                    id="form-group-default-checkin"
                    className={'form-group form-group-default form-group-default-checkin no-margin h-100\ ' + props.borderColor}>
                  <label id="checkin_label" className={'form-label\ ' + props.textColor}>
                    <FontAwesomeIcon icon={farCalendar}/> Check in
                  </label>
                  {
                      props.hasOwnProperty( 'calendar' ) &&
                      <CalendarCheckin
                          dateRange={localDateRange}
                          setDateRange={dateRangeHandler}
                          customTheme={props.calendar.customTheme}
                          focusToggle={props.calendar.focusToggle}
                          disableAllDatesAfterTwoYears={props.calendar.disableAllDatesAfterTwoYears}
                      />
                  }
                </div>
              </div>
              <div className={props.isManager == true ? 'col-6 col-lg-3 pb-3 pb-lg-0 px-2' : 'col-6 col-lg-4 pb-3 pb-lg-0 px-2'}>
                <div
                    id="form-group-default-checkout"
                    className={'form-group form-group-default form-group-default-checkout no-margin h-100\ ' + props.borderColor}>
                  <label id="checkout_label" className={'form-label\ ' + props.textColor}>
                    <FontAwesomeIcon icon={farCalendar}/> Check out
                  </label>
                  {
                      props.hasOwnProperty( 'calendar' ) &&
                      <CalendarCheckout
                          dateRange={localDateRange}
                          setDateRange={dateRangeHandler}
                          customTheme={props.calendar.customTheme}
                          focusToggle={props.calendar.focusToggle}
                          disableAllDatesAfterTwoYears={props.calendar.disableAllDatesAfterTwoYears}
                      />
                  }
                </div>
              </div>
              {
                props.bookingSearchbarWithBedrooms ?
                    <>
                      <div className={'col-12 col-lg-2 h-100 pb-3 pb-lg-0 px-2'}>
                        <div
                            className={'overflow-visible form-group form-group-default form-group-default-select2 bg-transparent no-margin no-arrow\ ' + props.borderColor + '\ ' + props.textColor}
                        >
                          <label htmlFor="bedrooms-select" className={'test-1 form-label\ ' + props.textColor}>
                            <FontAwesomeIcon icon={farBed}/> Bedrooms</label>
                          <Select
                              key={'bedrooms-select'}
                              name="bedrooms-select"
                              value={getBedroomValue(localBedrooms, props.bedroomOptions)}
                              inputId="bedrooms"
                              isSearchable
                              options={props.bedroomOptions}
                              styles={props.customStyles}
                              onChange={(newValue) => {
                                updateBedrooms(newValue, setLocalBedrooms)
                              }}
                              // blurInputOnSelect
                              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                          />
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div className={'col-12 col-lg-2 pb-3 pb-lg-0 px-2'}>
                        <div className={'form-group form-group-default no-margin h-100\ ' + props.buttonColor}>
                          {
                              props.hasOwnProperty( 'popover' ) &&
                              <>
                                <label id="guests_label" className={'form-label\ ' + props.textColor}>
                                  <FontAwesomeIcon icon={farUserFriends}/> Guests
                                </label>
                                <p className={'w-100 fs-14 fw-normal no-margin text-truncate opacity-70 hidden-xs hidden-sm\ ' + props.textColor}
                                   onClick={props.popover.handleOpen}
                                   aria-describedby={props.popover.id}
                                   style={{ cursor: 'pointer' }}
                                >
                                  {props.popover.guestsText}
                                </p>
                                <p
                                    className={'w-100 fs-14 fw-normal no-margin text-truncate opacity-70 visible-xs visible-sm visible-md\ ' + props.textColor}
                                    onClick={props.dialog.handleOpen}
                                    style={{ cursor: 'pointer' }}
                                >
                                  {props.popover.guestsText}
                                </p>
                                <ThemeProvider theme={props.popover.customTheme}>
                                  <Popover
                                      className="hidden-xs hidden-sm"
                                      id={props.popover.id}
                                      open={props.popover.open}
                                      anchorEl={props.popover.anchorEl}
                                      onClose={props.popover.handleClose}
                                      anchorOrigin={{
                                        vertical: props.position ? 'bottom' : 'top',
                                        horizontal: 'left',
                                      }}
                                      transformOrigin={{
                                        vertical: props.position ? 'top' : 'bottom',
                                        horizontal: 'left',
                                      }}
                                  >
                                    <Guests popover={props.popover}
                                            dialog={props.dialog}
                                            mobile={false}
                                            dispatchValues={false}
                                            adultsNum={localAdultsNum}
                                            kidsNum={localKidsNum}
                                            infantsNum={localInfantsNum}
                                            setAdultsNum={setLocalAdultsNum}
                                            setKidsNum={setLocalKidsNum}
                                            setInfantsNum={setLocalInfantsNum}
                                    />
                                  </Popover>
                                </ThemeProvider>
                                <ThemeProvider theme={props.dialog.customTheme}>
                                  <Dialog onClose={props.dialog.handleClose} open={props.dialog.open}>
                                    <Guests popover={props.popover}
                                            dialog={props.dialog}
                                            mobile={true}
                                            dispatchValues={false}
                                            adultsNum={localAdultsNum}
                                            kidsNum={localKidsNum}
                                            infantsNum={localInfantsNum}
                                            setAdultsNum={setLocalAdultsNum}
                                            setKidsNum={setLocalKidsNum}
                                            setInfantsNum={setLocalInfantsNum}/>
                                  </Dialog>
                                </ThemeProvider>
                              </>
                          }
                        </div>
                      </div>
                    </>

              }
              {
                props.isManager != true ?
                    <div className="col-12 col-lg-2 px-2">
                      <div className={'h-100 form-group form-group-default no-margin no-padding\ ' + props.buttonColor}>
                        <button form="frm_book_2"
                                className={'h-100 btn btn-primary bg-transparent no-border no-padding w-100\ ' + props.textColor}
                                aria-label="Search"
                                style={{ minHeight: '52px!important' }} type="submit">Search
                        </button>
                      </div>
                    </div>
                    :''
              }
            </div>
            {
              props.isManager == true ?
                  <div className="col-12 col-lg-2 px-2">
                    <div className={'h-100 form-group form-group-default no-margin no-padding\ ' + props.buttonColor}>
                      <button form="frm_book_2"
                              className={'h-100 btn btn-primary bg-transparent no-border no-padding w-100\ ' + props.textColor}
                              aria-label="Search"
                              style={{ minHeight: '52px!important' }} type="submit">
                        {
                          searchSpinner ?
                              <span>
                                <FontAwesomeIcon icon={faSpinner} spin className={'m-l-5 m-auto'}/> Searching
                              </span>
                              :
                              <span>
                                Search
                              </span>
                        }
                      </button>
                    </div>
                  </div>
                  :''
            }
          </form>
        </div>
      </div>
  )
}
